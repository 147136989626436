<template>
	<section class="domain_content">
		<h1 class="title">Panduan Menggunakan GB WhatsApp Versi Anti-Ban yang Disempurnakan 2024</h1>
		<div class="logo">
			<img
				alt="logo gbwhatsapp"
				src="@/assets/logo.webp"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Ban yang Disempurnakan 2024 1" />
		</div>
		<table class="app-info">
			<tr>
				<td>Aplikasi</td>
				<td>GBWhatsApp</td>
			</tr>
			<tr>
				<td>Ukuran</td>
				<td>77M</td>
			</tr>
			<tr>
				<td>Versi</td>
				<td>V18.10</td>
			</tr>
			<tr>
				<td>Pengembang</td>
				<td>AlexMODs</td>
			</tr>
			<tr>
				<td>Sistem</td>
				<td>Android 5+</td>
			</tr>
			<tr>
				<td>Sumber</td>
				<td>
					<a href="/">{{ host }}</a>
				</td>
			</tr>
			<tr>
				<td>Frekuensi Pembaruan</td>
				<td>Tidak Teratur</td>
			</tr>
			<tr>
				<td>Catatan Perubahan</td>
				<td>Perlindungan Anti-Ban yang Disempurnakan</td>
			</tr>
		</table>

		<div
			exp-data="show_gbhome_download"
			class="big-download-btn top-download fix has-exp"
			data-exposure="1"
			@click="download(apk)">
			<img
				:src="downloadicon"
				alt="unduh"
				class="download-img" />
			<div class="download-name">Unduh GB WhatsApp</div>
		</div>

		<!-- Daftar Isi -->
		<div
			id="toc_container"
			class="no_bullets">
			<section
				class="table-content"
				v-show="!tableHide">
				<div
					class="table-content-title"
					@click="tableHide = !tableHide">
					Daftar Isi
					<img
						src="@/assets/minus.svg"
						alt="dikurangi" />
				</div>

				<ul class="toc_list">
					<li>
						<a href="#tbc_1">
							<span class="toc_number toc_depth_1">1.</span>
							Unduh dan instal GBWhatsApp Terbaru yang Diperbarui
						</a>
					</li>
					<li>
						<a href="#tbc_2">
							<span class="toc_number toc_depth_1">2.</span>
							Fitur GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_3">
							<span class="toc_number toc_depth_1">3.</span>
							Perbedaan antara GBWhatsApp dan WhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_4">
							<span class="toc_number toc_depth_1">4.</span>
							Persyaratan untuk menginstal GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_5">
							<span class="toc_number toc_depth_1">5.</span>
							Manfaat menggunakan GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_6">
							<span class="toc_number toc_depth_1">6.</span>
							Apakah aman menggunakan GBWhatsApp?
						</a>
					</li>
					<li>
						<a href="#tbc_7">
							<span class="toc_number toc_depth_1">7.</span>
							Bagaimana cara mencadangkan GB WhatsApp?
						</a>
					</li>
					<li>
						<a href="#tbc_8">
							<span class="toc_number toc_depth_1">8.</span>
							Bagaimana cara memperbarui versi GBWhatsApp yang kedaluwarsa?
						</a>
					</li>
					<li>
						<a href="#tbc_9">
							<span class="toc_number toc_depth_1">9.</span>
							FAQ untuk GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_10">
							<span class="toc_number toc_depth_1">10.</span>
							Ulasan pengguna untuk GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_11">
							<span class="toc_number toc_depth_1">11.</span>
							Kesimpulan
						</a>
					</li>
				</ul>
			</section>
			<section
				class="table-content hide"
				v-show="tableHide">
				<div
					class="table-content-title"
					@click="tableHide = !tableHide">
					Daftar Isi
					<img
						src="@/assets/plus.svg"
						alt="ditambah" />
				</div>
			</section>
		</div>

		<h2 id="tbc_1"><strong>Unduh dan instal GBWhatsApp Terbaru yang Diperbarui</strong></h2>
		<p>Di situs web ini, kami mengunggah file APK GBWhatsApp asli, yang telah dimodifikasi oleh AlexMODs. GB WhatsApp V18.10 menawarkan fitur-fitur baru yang menarik seperti gaya status teks, menolak panggilan (online, offline, saat menelepon), dan manajemen grup tingkat lanjut untuk admin. Untuk menikmati fitur-fitur ini, unduh hari ini.</p>

		<p class="pic-fit">
			<img
				alt="unduh apk gbwhatsapp"
				src="@/assets/gb-1.webp"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Ban yang Disempurnakan 2024 2" />
		</p>

		<p>
			<strong>Langkah 1:</strong>
			Aktifkan fungsi
			<strong>sumber tidak dikenal</strong>
			di ponsel Anda.
		</p>

		<p>
			<strong>Langkah 2:</strong>
			Klik tombol unduh di bawah ini:
		</p>

		<div
			exp-data="show_gbhome_download"
			class="big-download-btn top-download fix has-exp"
			data-exposure="1"
			@click="download(apk)">
			<img
				:src="downloadicon"
				alt="unduh"
				class="download-img" />
			<div class="download-name">Unduh GBWhatsApp</div>
		</div>

		<p>
			<strong>Langkah 3:</strong>
			Percayai file apk GBWhatsApp dan klik
			<strong>OK</strong>
			untuk menginstalnya.
		</p>

		<p class="pic-fit landscape">
			<img
				alt="centang opsi"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Ban yang Disempurnakan 2024 3"
				src="@/assets/NTI5ZDA5NWQ4MjQ2MWVlOTY1MmM4OWJkZmQ4MWIxMGFfVTQwaVo2NWM4N3NoNmd6T3F6REhzVzhWZmFZajh0c1ZfVG9rZW46SkZteWI2RXBpb3BuWTd4UERaT2NnV2dEbmdmXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<p>
			<strong>Langkah 4:</strong>
			Luncurkan aplikasi dan verifikasi nomor telepon Anda.
		</p>

		<p class="pic-fit landscape">
			<img
				alt="instal apk gbwhatsapp"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Ban yang Disempurnakan 2024 4"
				src="@/assets/YjhlZjc4NGUwNDY0MWQ0ZTA3Mjg4OTc2Yzk3ZGE5NjFfaGVHalliYTVscUNBODIyZ0RkOGxjekJrTDJGZDJiTWtfVG9rZW46Q2VhWWJ4eGxKb0diakd4RGQwd2NkenV3bkJiXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<h2 id="tbc_2">
			<strong>Fitur GBWhatsApp</strong>
		</h2>

		<p>Antarmuka GBWhatsApp tidak jauh berbeda dengan WhatsApp resmi. Jika Anda pernah menggunakan WhatsApp, Anda pasti tahu cara menggunakan GBWhatsApp.</p>

		<p>Dibandingkan dengan WhatsApp resmi, GBWhatsApp menyediakan lebih banyak fitur. Dan, Anda juga dapat membuka Pengaturan untuk merasakan lebih banyak lagi. Kami mencantumkan berbagai fitur GBWhatsApp yang dapat Anda gunakan.</p>

		<p class="feature-title"><strong>Balas Otomatis</strong></p>

		<p>Pertama, Anda dapat menggunakan fitur balas otomatis ini saat ingin membalas salah satu teman Anda kapan saja. Anda dapat menggunakan ini untuk mempromosikan bisnis Anda dengan mengirimkan pesan massal tanpa batas ke obrolan atau grup mana pun.</p>

		<p class="feature-title"><strong>Tema</strong></p>

		<p>Tema disertakan dalam GBWA, dan sekarang menjadi salah satu fitur terbaik mod. Fitur ini memungkinkan Anda mengubah tata letak aplikasi kapan pun Anda mau, sesering yang Anda suka. Beberapa tema sudah tersedia, dan pengembang terus menambahkan lebih banyak lagi.</p>

		<p class="feature-title"><strong>Pesan Anti-Hapus</strong></p>

		<p>Dilengkapi dengan fitur pesan anti-pencabutan, pesan yang dihapus oleh siapa pun dari sisinya, Anda masih dapat melihat pesan yang dihapus tersebut secara default. Jika tidak perlu, jangan balas pesan yang dihapus.</p>

		<p class="feature-title"><strong>Pengirim Pesan Massal</strong></p>

		<p>Alat ini memungkinkan Anda mengirim pesan massal, yang berarti Anda dapat mengirim pesan tanpa batas ke kontak dan mengerjai teman Anda dengan alat yang luar biasa ini.</p>

		<p class="feature-title"><strong>Kirim Gambar Maksimum</strong></p>

		<p>Selain itu, dibandingkan dengan WhatsApp resmi, Anda dapat mengirim lebih dari 90 gambar sekaligus. Anda juga dapat mengirim klip video 50 MB dan klip audio 100 MB ke kontak Anda.</p>

		<p class="feature-title"><strong>Sembunyikan Status Dilihat</strong></p>

		<p>Anda dapat memeriksa dan mengunduh status teman Anda kapan saja, tetapi jika Anda tidak ingin mereka tahu bahwa Anda telah melihat status mereka, Anda dapat melakukannya hanya dengan satu klik.</p>

		<p class="feature-title"><strong>Kirim Gambar Berkualitas Tinggi</strong></p>

		<p>Terkadang kita harus mengirim gambar dengan kualitas asli, dan untuk itu, kita menggunakan Opsi Dokumen. Sekarang GBWhatsApp memungkinkan Anda mengirim gambar dengan kualitas tertinggi.</p>

		<p class="feature-title"><strong>Mode DND</strong></p>

		<p>Banyak pengguna menikmati fungsi yang damai. Saat Anda menggunakan beberapa aplikasi lain di ponsel Android Anda, aktifkan Mode DND. Ini memungkinkan Anda untuk berhenti menerima pesan hingga dimatikan.</p>

		<p class="pic-fit landscape">
			<img
				alt="mode dnd"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Blokir yang Disempurnakan 2024 5"
				src="@/assets/ODJjYTk4OTBkMjljYjhjMzE0YzFkYTI1NTkyNjhmZmJfN3pUdFMya1pMclppR1dJcXdQTjhpaUQ3VDd4N0Jua2dfVG9rZW46U2h1d2JHdHE4b3Q5bXp4QWhiTGNxbGJybkJoXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<p class="feature-title"><strong>Unduh Status</strong></p>

		<p>Fitur hebat lainnya dari aplikasi ini adalah Anda dapat mengunduh gambar dan video status yang diunggah oleh kontak lain. Sekarang Anda tidak perlu menggunakan Aplikasi pihak ketiga apa pun, Simpan segala jenis Status dari daftar kontak Anda.</p>

		<p class="feature-title"><strong>Kunci Aplikasi</strong></p>

		<p>Kunci aplikasi memungkinkan Anda untuk meminta Pola, PIN, Kata Sandi, atau Sidik Jari untuk masuk ke aplikasi dan mengakses pesan Anda. Ini menambahkan lapisan keamanan dan menjaga pesan Anda tetap tersembunyi bahkan dari orang yang memiliki akses ke ponsel Anda. PIN dan kata sandi dapat ditampilkan saat Anda mengetiknya jika Anda mau.</p>

		<p class="feature-title"><strong>Terjemahan dalam Obrolan Tambahan</strong></p>

		<p>Bengali, Tamil, Gujarati, Punjabi, Urdu, dan Vietnam sekarang didukung oleh GBWhatsApp. Terjemahkan pesan obrolan apa pun secara instan ke dalam lebih dari 50 bahasa.</p>

		<p class="feature-title"><strong>Emoji</strong></p>

		<p>Daripada menanggapi komunikasi dengan emoji, Anda sekarang dapat bereaksi terhadap pesan apa pun. Lima emoji disertakan dalam koleksi WhatsApp default. Namun, dengan aplikasi GB WhatsApp, Anda dapat menyesuaikan kumpulan emoji Anda sendiri untuk bereaksi.</p>

		<p class="feature-title"><strong>⭐Gaya dan tombol baru untuk Widget WhatsApp (Baru Ditambahkan)</strong></p>
		<p>GB WhatsApp membuat widget WhatsApp gaya baru untuk dipilih pengguna. Ini adalah fitur yang baru ditambahkan dan menarik banyak pengguna.</p>

		<p class="pic-fit landscape">
			<img
				alt="tambahkan widget"
				title="Panduan Menggunakan GB WhatsApp Versi Anti-Blokir yang Disempurnakan 2024 6"
				src="@/assets/MDVhZDM5ZjMxNjZkMDY1ODJiMzA3NDI3ZGY2NWNjNzhfRDZ5ZUVXNWVRdkM0cjhydmxheHV2VVlndGZma1BEYWhfVG9rZW46VExldmJKNHlTb2RCekl4Uzc3UWM3TVkzbmNsXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<p class="feature-title"><strong>⭐Buka pesan pertama</strong></p>
		<p>Versi terbaru GB WhatsApp memungkinkan pengguna untuk membuka pesan pertama selama mengklik opsi tersebut. Ini menghemat waktu untuk menggulir ke atas dan ke bawah untuk menemukan pesan pertama dalam obrolan.</p>

		<p>
			<strong>Catatan</strong>
			: Ada satu hal yang harus Anda perhatikan, GBWhatsApp tidak tersedia untuk sistem iOS. Jadi, pengguna iPhone harus menggunakan yang resmi atau mencari versi modifikasi lainnya.
		</p>

		<h2 id="tbc_3"><strong>Perbedaan antara GBWhatsApp dan WhatsApp</strong></h2>
		<table class="d-table">
			<tr>
				<th>Fitur</th>
				<th>GB WhatsApp</th>
				<th>WhatsApp</th>
			</tr>
			<tr>
				<td>Mode Hantu</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Kualitas Gambar Tinggi</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Nonaktifkan Panggilan</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Pesan Massal</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Pesan Belum Dibaca</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Sembunyikan Status Online</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Balas Otomatis</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Anti Hapus</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Simpan Status</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>4000+ Tema</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Ubah Tanda Centang Biru</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Lihat Pesan yang Diedit</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Chat AI</td>
				<td>❌</td>
				<td>✔️</td>
			</tr>
			<tr>
				<td>Pesan Otomatis</td>
				<td>✔️</td>
				<td>✔️</td>
			</tr>
			<tr>
				<td>Durasi Status</td>
				<td>7 menit</td>
				<td>30 detik</td>
			</tr>
			<tr>
				<td>Batas Pesan Suara</td>
				<td>100MB</td>
				<td>16MB</td>
			</tr>
			<tr>
				<td>Dukungan</td>
				<td>Dukungan resmi tersedia</td>
				<td>
					Forum seperti
					<a
						href="https://www.reddit.com/r/GBWhatsapp/"
						rel="nofollow"
						target="_blank">
						Reddit
					</a>
				</td>
			</tr>
		</table>
		<h2 id="tbc_4"><strong>Persyaratan untuk menginstal GBWhatsApp</strong></h2>
		<table>
			<tr>
				<th>Item</th>
				<th>Persyaratan</th>
			</tr>
			<tr>
				<td>Akses Internet</td>
				<td>Koneksi WiFi</td>
			</tr>
			<tr>
				<td>Sistem Ponsel</td>
				<td>Hanya Android</td>
			</tr>
			<tr>
				<td>Root</td>
				<td>Tidak diperlukan</td>
			</tr>
			<tr>
				<td>Penyimpanan</td>
				<td>Ruang penyimpanan internal yang cukup</td>
			</tr>
		</table>

		<h2 id="tbc_5"><strong>Manfaat Menggunakan GB WhatsApp</strong></h2>
		<p>
			<strong>Opsi Kustomisasi:</strong>
			GB WhatsApp memungkinkan Anda untuk mempersonalisasi pengalaman obrolan dengan tema, font, dan wallpaper khusus. Untuk memaksimalkan fitur ini, jelajahi perpustakaan tema aplikasi dan coba berbagai kombinasi sesuai gaya Anda.
		</p>
		<p>
			<strong>Peningkatan Privasi:</strong>
			Dengan fitur seperti menyembunyikan status online, centang ganda, dan indikator mengetik, GB WhatsApp menawarkan lebih banyak kontrol atas privasi Anda. Gunakan pengaturan ini dengan bijak untuk menjaga kerahasiaan dalam obrolan Anda tanpa terlihat offline oleh kontak saat diperlukan.
		</p>
		<h2 id="tbc_6"><strong>Apakah Aman Menggunakan GB WhatsApp daripada WhatsApp Resmi?</strong></h2>
		<p>GB WhatsApp APK adalah versi modifikasi berdasarkan WhatsApp tanpa operasi berisiko. Kekhawatiran orang-orang terkait dengan penggunaan GB WhatsApp adalah risiko pemblokiran akun oleh WhatsApp. Tidak ada bukti konkret yang menunjukkan bahwa aplikasi itu sendiri tidak aman. Pengguna harus menyadari risiko potensi pemblokiran, namun hingga saat ini, belum ada kerentanan keamanan signifikan yang terbukti terkait dengan GB WhatsApp.</p>
		<p>Mendaftarkan akun baru atau secara konsisten menggunakan versi terbaru GB WhatsApp dapat membantu meningkatkan keamanan akun Anda. Dengan menjaga aplikasi tetap diperbarui, Anda mengurangi kemungkinan menghadapi masalah, karena versi baru sering kali memperbaiki kerentanan potensial dan menyertakan peningkatan yang dirancang untuk melindungi akun pengguna.</p>
		<h2 id="tbc_7"><strong>Bagaimana Cara Mencadangkan Data GB WhatsApp?</strong></h2>
		<p>
			GB WhatsApp tidak mendukung pencadangan langsung riwayat obrolan atau file media ke akun Google; sebaliknya, semua pesan disimpan secara lokal di memori ponsel Anda. Untuk mentransfer atau mencadangkan obrolan Anda, Anda harus secara manual mengakses file melalui
			<strong>Manajer File</strong>
			ponsel Anda. Temukan folder GB WhatsApp dan salin semuanya ke komputer Anda.
		</p>
		<p>Untuk detail lebih lanjut, Anda dapat membaca blog kami.</p>
		<h2 id="tbc_8"><strong>Bagaimana Cara Memperbarui Versi Kadaluarsa GB WhatsApp?</strong></h2>
		<p>GB WhatsApp terus diperbarui secara tidak teratur. Jika GB WhatsApp kadaluarsa, pengguna perlu mengunduh versi terbaru untuk melindungi akun mereka. Setelah GB WhatsApp Anda kadaluarsa, Anda dapat mengunjungi situs kami untuk mengunduh APK terbaru untuk Android. Atau, perbarui aplikasi langsung dengan mengikuti langkah-langkah berikut:</p>
		<ul>
			<li>Luncurkan GB WhatsApp dan buka Pengaturan GB.</li>
			<li>
				Cari opsi
				<strong>Pembaruan</strong>
				dan periksa apakah ada titik merah yang menunjukkan versi baru.
			</li>
			<li>Aplikasi GB WhatsApp mungkin akan meminta Anda memperbarui ke versi baru jika telah dirilis.</li>
		</ul>
		<div class="faq">
			<h2 id="tbc_9"><strong>FAQ untuk GB WhatsApp</strong></h2>
			<div
				v-for="(item, index) in faqs"
				:key="index"
				class="dropdown-faq">
				<h4 @click="toggleFaq(index)">{{ item.question }}</h4>
				<div
					v-if="activeIndex === index"
					class="content">
					<p>{{ item.answer }}</p>
				</div>
			</div>
		</div>
		<!-- tbc_10 -->
		<h2 id="tbc_10">Ulasan Pengguna untuk GBWhatsApp</h2>
		<p class="text-center">Di mana kita bisa mengetahui keunggulan GBWhatsApp? Selalu ada di ulasan pengguna.</p>
		<p class="text-center">⭐⭐⭐⭐⭐</p>
		<p class="pic-fit landscape">
			<img
				alt="ulasan gbwhatsapp"
				src="@/assets/N2YxMTBkNzk2ODI4YTc3N2EwYWQxMTA1Y2E3YWE1MDhfS0JQazB6RnNRb2ZlRUdwa0tQMG1ESnN5RzNxQzVzeGFfVG9rZW46WG5TQWJ6cXpab1RCNFZ4YnA4amNhQ3ZIbnRkXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>
		<h2 id="tbc_11">Kesimpulan</h2>
		<p>Mudah untuk mengetahui bahwa pengguna GBWhatsApp lebih suka menggunakannya, karena membuka dunia kustomisasi dan fitur privasi. Bergabunglah dengan grup pengguna GBWhatsApp, mari rasakan pengalaman aplikasi pesan tanpa batas. Jangan lupa untuk menandai situs kami.</p>
	</section>
</template>

<script>
import '@/css/default.scss'
import { mapGetters } from 'vuex'

export default {
	...{
		metaInfo: {
			title: 'Situs Resmi: Unduh Versi Anti-Ban GB WhatsApp 2024 (Terbaru dan Diperbarui!)',
			meta: [
				{ name: 'description', content: 'Bagaimana cara menggunakan GBWhatsApp? Anda dapat mengunduh versi terbaru GBWhatsApp dengan perlindungan anti-ban terlebih dahulu dan membaca panduan langkah demi langkah.' },
				{ name: 'title', content: 'Unduh Versi Anti-Ban GBWhatsApp dan Periksa Panduan Pengguna' },
				
				{ property: 'og:title', content: 'Unduh Versi Anti-Ban GBWhatsApp dan Periksa Panduan Pengguna' },
				{ property: 'og:description', content: 'Bagaimana cara menggunakan GBWhatsApp? Anda dapat mengunduh versi terbaru GBWhatsApp dengan perlindungan anti-ban terlebih dahulu dan membaca panduan langkah demi langkah.' },
			],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.net/id/"
            }]
		},
	},
	data() {
		return {
			from: null,
			filename: null,
			partners: null,
			updateTime: null,
			clicks: null,
			pageIntSuccess: null,
			apk: null,
			tableHide: true,
			activeIndex: null,
			faqs: [
				{
					question: 'Apakah tersedia untuk iPhone?',
					answer: 'Tidak, GB WhatsApp tidak tersedia untuk sistem iOS.',
				},
				{
					question: 'Apakah GBWhatsApp memiliki versi web atau desktop?',
					answer: 'Tidak. Tetapi Anda dapat menggunakan aplikasi pihak ketiga untuk mencerminkan layar ponsel Anda ke desktop Anda.',
				},
				{
					question: 'Apa itu GB WhatsApp?',
					answer: 'Ini adalah versi modifikasi dari WhatsApp yang menyediakan lebih banyak fitur daripada WhatsApp asli. Ini menarik jutaan pengguna untuk mengunduhnya.',
				},
			],
		}
	},
	computed: {
		...mapGetters(['showDebug', 'host', 'downloadicon']),
	},
	created() {
		this.initParams()
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		toggleFaq(index) {
			this.activeIndex = this.activeIndex === index ? null : index
		},
		download(apk) {
			this.$global.download(apk)
		},
		initParams() {
			this.from = this.$global.GetQueryString('from')
			this.filename = this.$global.GetQueryString('filename')
		},
		fetchData() {
			if (this.from) {
				this.getAPK(true, this.from, this.filename)
			} else {
				this.getAPK()
				this.getOfficialApk()
			}
		},
		updateAPK() {
			this.$emit('update-apk', this.apk)
		},
		getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
			this.$server
				.getAPPInfo({
					promotionChannel: from,
					promotionName: filename,
				})
				.then((res) => {
					if (res.code === 200) {
						this.partners = res.data.partners
						this.updateTime = res.data.updateTime
						this.clicks = res.data.clicks
						this.pageIntSuccess = true
						if (isFromQuery) {
							this.apk = res.data.apk
							this.updateAPK()
						}
					}
				})
				.catch((err) => {
					console.error('Error fetching APK:', err)
				})
		},
		getOfficialApk() {
			this.$server
				.getOfficialApk({
					domain: 'gbpro.download',
					appName: 'GB_gbpro.download',
				})
				.then((res) => {
					if (res.code === 200) {
						this.apk = res.data.apk
						this.updateAPK()
						console.log('APK fetched successfully')
					}
				})
				.catch((err) => {
					console.error('Error fetching official APK:', err)
				})
		},
	},
}
</script>
