<template>
	<div id="footer">
		<div class="footer-row">
			<div class="footer-logo-row">
				<link rel="preload" href="@/assets/GBPRO@2x.webp" as="image">

				<img
				src="@/assets/GBPRO@2x.webp"
				class="footer-img"
				alt="logo"
				 />
				<div class="footer-name">GB WhatsApp</div>
			</div>
			<div
			class="terms-lang-switch footer-page"
			v-if="isPrivacy(['whatsapppro.org', 'waproapk.net', 'gbwhatsapk.net', 'gbapks.org.in', 'waplusapk.download', 'waproapk.com'])">
				<ul class="terms list-inline-w-separator">
					<li>
						<a
							href="/privacy"
							title="Privacy Policy">
							Privacy Policy
						</a>
					</li>
					<li>
						<a
							href="/disclaimer"
							title="Disclaimer">
							Disclaimer
						</a>
					</li>
					<li>
						<a
							href="/about-us"
							title="About Us">
							About Us
						</a>
					</li>
				</ul>
			</div>
			<div class="social">
				<a
					href="https://www.facebook.com/"
					target="_blank"
					rel="noopener noreferrer"
					title="Facebook">
					<link rel="preload" href="@/assets/facebook.webp" as="image">

					<img
						src="@/assets/facebook.webp"
						alt="facebook"/>
				</a>
				<a
					href="https://x.com/"
					target="_blank"
					rel="noopener noreferrer"
					title="X">
					<link rel="preload" href="@/assets/twitter.webp" as="image">

					<img
						src="@/assets/twitter.webp"
						alt="X"/>
				</a>
				<a
					href="https://www.instagram.com/"
					target="_blank"
					rel="noopener noreferrer"
					title="Instagram">
					<link rel="preload" href="@/assets/instagram.webp" as="image">
					<img
						src="@/assets/instagram.webp"
						alt="instagram"/>
				</a>
			</div>
		</div>
		<div class="copyright">
			<p>
				Copyright © 2024
				<a href="/">GB WhatsApp</a>
				<sup>®</sup>
				. All rights reserved.
			</p>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FooterComponent',
	computed: {
		...mapGetters(['showDebug', 'host']),
		// isValidHost() {
		//   console.log('host: ', this.host)
		// 	const validLocalhost = /^localhost(:\d+)?$/
		// 	const domain_12 = /^(https?:\/\/)?(www\.)?whatsapppro\.org$/
		// 	const domain_11 = /^(https?:\/\/)?(www\.)?waproapk\.net$/
		// 	if (!this.host) {
		// 		return false
		// 	}

		// 	return validLocalhost.test(this.host) || domain_12.test(this.host)
		// },
	},
	methods: {
		isPrivacy(arr) {
			console.log('isPrivacy: ', arr)
			if (this.host.length === 0) return true
			// const validLocalhost = /^localhost(:\d+)?$/

			return arr.some((e) => {
				return this.host.indexOf(e) >= 0
			})
		},
	},
}
</script>
