<template>
<!-- Recent Posts -->
<div id="rec_post_container" class="no_bullets">
    <h3>
        {{translatedTitle}}
    </h3>
    <ul class="rec_post_list">
        <li v-for="(item, index) in sortedBlogList" :key="index">
            <a :href="item.url">
                <div class="rec_post_content">
                    {{ item.title }}
                </div>
            </a>
        </li>
        <li>
            <a href="/gb-whatsapp-for-pc">
                <div class="rec_post_content">
                    GB WhatsApp for PC
                </div>
            </a>
        </li>
        <li>
            <a href="/gb-whatsapp-old-versions">
                <div class="rec_post_content">
                    GB WhatsApp Old Versions
                </div>
            </a>
        </li>
    </ul>
</div>
</template>

<script>
import '@/css/default.scss';
export default {
    props: {
        doc: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            default: 'en'
        }
    },

    computed: {
        translatedTitle() {
            const titles = {
                en: "Recent Posts",
                es: "Publicaciones Recientes", // Spanish
                pt: "Postagens Recentes", // Portuguese
                id: "Pos Terbaru", // Bahasa Indonesia
                hi: "हाल के पोस्ट", // Hindi
                ar: "المنشورات الأخيرة", // Arabic
                de: "Aktuelle Beiträge" // German
            };

            // Return the title based on the selected language, default to English
            return titles[this.language] || titles.en;
        },
        sortedBlogList() {
            return this.doc.blogList.slice().reverse();
        }
    }
}
</script>
