<template>
<section class="domain_content">
    <h1 class="title">
        Unlock the Extra Features of GB WhatsApp 2024
    </h1>

    <p class="pic-fit">
        <img src="@/assets/domain15-1.webp" alt="verified GB WhatsApp" title="Unlock the Extra Features of GB WhatsApp 2024 1">
    </p>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img" />
        <div class="download-name">Download GB WhatsApp</div>
    </div>

    <p>
        In modern life, no one can live without a mobile phone and an message app in it. <a target="_blank" rel="nofollow noopener noreferrer" href="https://www.whatsapp.com/">WhatsApp</a> is one of the instant message app which can help you get contacts with people. Till 2024, WhatsApp has gained billions of users across the world. Then, some mod app for WhatsApp has been developed. People search for more features and someone solves their needs.
    </p>

    <p>
        GB WhatsApp is one of the famous modified version of original WhatsApp. It is not that popular but can decrease the ban risks compared to other mod apps.
    </p>

    <TOC :tocList="toc" />

    <h2 id="tbc_1">
        {{toc[0]}}
    </h2>

    <p>
        GB WhatsApp is completely developed based on the official WhatsApp. Users can use is as a new version of WhatsApp to contact others. The messages sent by GB WhatsApp users can also be received by WhatsApp users. They share the same system.
    </p>

    <p>
        So, typing messages, making video calls, sending audio messages, creating or joining groups are all available features of GB WhatsApp. As for the extra features, we divided them into three parts. Please read continue.
    </p>
    <div class="features">
        <p class="pic-fit">
            <img src="@/assets/domain15-2.webp" alt="custom features" title="Unlock the Extra Features of GB WhatsApp 2024 2">
        </p>

        <p>
            <strong>Themes</strong> <br>
            One of the popular features of GB WhatsApp is the customization of themes. It means users can change the themes from a theme store which contains 4000+ amounts. Users can design the color, the font, the chat background in GB WhatsApp.
        </p>

        <p>
            <strong>Blue Ticks icon</strong> <br>
            It's really amazing that the traditional sign of reading messages can be changed in GB WhatsApp. The developers provide a bunch of icons for you to choose to replace the blue ticks.
        </p>

        <p>
            <strong>Mark as Unread</strong> <br>
            Users can set any messages as unread. It's convenient for users to read them later when there is no time to read them instantly.
        </p>

        <p>
            <strong>Filter Messages</strong> <br>
            GB WhatsApp also adds the filter messages feature to filter specific text or image messages simply.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-3.webp" alt="privacy features" title="Unlock the Extra Features of GB WhatsApp 2024 3">
        </p>

        <p>
            <strong>Ghost Mode</strong> <br>
            It is hidden in the GB WhatsApp settings. Go to the privacy option and enable this option. You'll get a ghost icon on the main interface bar. Then, you can open or close the mode easily.
        </p>

        <p>
            <strong>Block Incoming Calls</strong> <br>
            Simply as the feature name mentioned, incoming calls can be blocked as long as you set the limits.
        </p>

        <p>
            <strong>Hide Double-Blue Ticks</strong> <br>
            Have you wondered if the double ticks can be hidden? In GB WhatsApp, you can achieve this. Blue ticks can be hidden and you can read the messages freely.
        </p>

        <p>
            <strong>Anti-Deleted/Revoked Messages</strong> <br>
            GB WhatsApp inserted a feature to anti-revoked messages. You can still see the deleted or revoked messages in your chat box. And deleted messages can also be searched in chat history.
        </p>

        <p>
            <strong>Hide View Track</strong> <br>
            Users can see the statuses of contacts without notifying them. The view track can be hidden.
        </p>

        <p>
            <strong>Hide Your Status</strong> <br>
            The 'typing...' or the 'recording audio...' to contacts, groups, or others appearing on the top bar of a chat box can be hidden in GB WhatsApp.
        </p>

        <p>
            <strong>Disable Forward</strong> <br>
            When you forward a message or photo from another chat, you can remove the Forward label.
        </p>

        <p class="pic-fit">
            <img src="@/assets/domain15-4.webp" alt="other features" title="Unlock the Extra Features of GB WhatsApp 2024 4">
        </p>
        <ul>
            <li>Lock App for security</li>
            <li>Send images and videos up to 700M without compression</li>
            <li>Send messages directly to phone numbers</li>
        </ul>
    </div>

    <h2 id="tbc_2">
        {{toc[1]}}
    </h2>
    <table>
        <tr>
            <th>Features</th>
            <th>GB WhatsApp</th>
            <th>WhatsApp</th>
        </tr>
        <tr>
            <td><strong>Ghost Mode</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Mark Unread Messages</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Hide Status</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Change Blue Ticks</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Hide Blue Ticks</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Block Video Call</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>DND Mode</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Read Deleted Messages</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Change Themes</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Change Chat Bubbles</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Effects</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Mark as Unread</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Schedule Messages</strong></td>
            <td>✔️</td>
            <td>❌</td>
        </tr>
        <tr>
            <td><strong>Photo Numbers</strong></td>
            <td>100 MB</td>
            <td>100 MB</td>
        </tr>
        <tr>
            <td><strong>Media Files Quality</strong></td>
            <td>No Compression</td>
            <td>Compression</td>
        </tr>
        <tr>
            <td><strong>Filter Messages</strong></td>
            <td>✔️</td>
            <td>✔️</td>
        </tr>
        <tr>
            <td><strong>Help Centre</strong></td>
            <td>❌</td>
            <td>✔️</td>
        </tr>
    </table>

    <h2 id="tbc_3">
        {{toc[2]}}
    </h2>

    <ul>
        <li><strong>Before Download APK Files:</strong><br>
            Any Apk files from third-party will be detected as an unknown source by Android system when installing. So, users should enable <strong>allow from this source</strong>. It can also be found under "Special permissions" on Android phones.
            <p class="pic-fit landscape">
                <img src="@/assets/domain15-5.webp" alt="install unknown apps" title="Unlock the Extra Features of GB WhatsApp 2024 5">
            </p>
        </li>
        <li><strong>Install GB WhatsApp:</strong><br>
            After confirm the permission to launch the installation of GB WhatsApp, users can follow the guide to finish the rest process. Confirm that you're aware of the risks and give permission. Later, you can use the app.
        </li>
    </ul>

    <h2 id="tbc_4">
        {{toc[3]}}
    </h2>
    <p>
        To transfer data from WhatsApp to GB WhatsApp, you should backup the data a first. Go to Privacy > Chat > Chat History, then set the backup frequency to daily. Usually, it will upload the data to Google. But GB WhatsApp can't access data from Google, cause it's not that official.
    </p>

    <p>
        Follow the steps below to finish transferring:
        <ul>
            <li><strong>Step 1: </strong>Disable backup to Google account. There is no button for this. But you can try disconnecting to WiFi or Cellular.</li>
            <li><strong>Step 2: </strong>Go to the <strong>File Manager</strong> in Android phones, and go to <strong>Android > Media > com.whatsapp > WhatsApp</strong>. Data will be saved there. You can check if the update time is the day you backup.
                <p class="pic-fit landscape">
                    <img src="@/assets/domain15-6.webp" alt="folder" title="Unlock the Extra Features of GB WhatsApp 2024 6">
                </p>
            </li>
            <li><strong>Step 3: </strong>Copy <strong>Backups</strong>, <strong>Database</strong> and <strong>Media</strong> folders and paste them to another folder. Remember the location of the folder you paste them in. We need it in the following steps.</li>
            <li><strong>Step 4: </strong>Uninstall WhatsApp. If you're worried, you can choose not to clear the cache. </li>
            <li><strong>Step 5: </strong>Create a folder named <strong>com.gbwhatsapp</strong> under <strong>Media</strong>. Create a <strong>GB WhatsApp</strong> folder under it. Then move the <strong>Backups</strong>, <strong>Database</strong> and <strong>Media</strong> folders in it. </li>
            <li><strong>Step 6: </strong>Back to the phone interface and launch GB WhatsApp, you can restore data this time.</li>
        </ul>
    </p>

    <h2 id="tbc_5">
        {{toc[4]}}
    </h2>
    <ol>
        <li>Upgrade Latest version from inner GB WhatsApp: Check the current version of GB WhatsApp in App Info, if there is new version released, it will notice updating. </li>
        <li>Download the latest version from Website: Visit this trusted GB WhatsApp website to download the latest APK file. </li>
    </ol>
    <h4 style="text-align:center;">
        App Information
    </h4>
    <table>
        <tr>
            <th>Name</th>
            <th>GB WhatsApp</th>
        </tr>
        <tr>
            <td>Size</td>
            <td>77MB</td>
        </tr>
        <tr>
            <td>Version</td>
            <td>18.10</td>
        </tr>
        <tr>
            <td>Update</td>
            <td>1 day ago</td>
        </tr>
        <tr>
            <td>License</td>
            <td>Free</td>
        </tr>
    </table>
    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img" />
        <div class="download-name">Download GB WhatsApp 18.10</div>
    </div>

    <h2 id="tbc_6">
        {{toc[5]}}
    </h2>
    <p>
        <strong>GB WhatsApp Accounts: </strong> Since the official WhatsApp and GB WhatsApp shares the same account, and the official WhatsApp team enhanced their anti-modified mechanism. Users of GB WhatsApp may face the risks of account bans. It's inevitable, so, a large group of users choose to use a secondary phone number to login GB WhatsApp app.
    </p>
    <p>
        <strong>Personal Information Leakage: </strong>Concerns vary from different people. Some of them installed not only one mod or cracked version app. Some highly cautious about personal information. There is no direct evidence to clarify the issue of information stealing.
    </p>

    <h2 id="tbc_7">
        {{toc[6]}}
    </h2>
    <FAQ :faqs="faqs" />

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import TOC from '@/components/TOC.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    ...{
        "metaInfo": {
            "title": "Safe Download GB WhatsApp 2024 to Unlock Extra Features",
            "meta": [{
                "name": "description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }, {
                "name": "title",
                "content": "GBWhatsApp"
            },
             {
                "property": "og:title",
                "content": "GBWhatsApp"
            }, {
                "property": "og:description",
                "content": "The Mod version of WhatsApp named GB WhatsApp has more features than the original one. Visit the site to download the latest GB WhatsApp APK to use it without banning. "
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapps.homes/"
            }]
        }
    },
    components: {
        TOC,
        FAQ
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            language: 'en',
            toc: [
                'Why do people choose to use GB WhatsApp?',
                'Comparison of GB WhatsApp and WhatsApp',
                'Download and install GB WhatsApp',
                'Transfer data from WhatsApp to GB WhatsApp',
                'Methods to update GB WhatsApp Apk file',
                'Security issues: Is GB WhatsApp Safe?',
                'FAQ'
            ],
            faqs: [{
                    question: 'What\'s new in the latest version of GB WhatsApp?',
                    answer: 'GB WhatsApp latest version fixed some bugs and enhanced anti-ban protection.',
                },
                {
                    question: 'Can I use the old version of GB WhatsApp?',
                    answer: 'If you\'re using the old version, you can keep it. But don\'t forget to backup data regularly. If it\'s too old, you can upgrade it to the newest version.',
                },
                {
                    question: 'GB WhatsApp for iPhone',
                    answer: 'GB WhatsApp doesn\'t support iOS system. iPhone users can\'t use it. If you\'re interested in it, you can search for installing an Android system on YouTube.',
                },
                {
                    question: 'What is Blue WhatsApp Plus?',
                    answer: 'BlueMods team developed Blue WhatsApp Plus with more features than official WhatsApp. It\'s another MOD version app. Same features can be found in it but with little differences in logo and interface.',
                },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
