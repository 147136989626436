<template>
	<section class="domain_content">
		<h1 class="title">Guía de uso de la versión mejorada anti-baneo de GB WhatsApp 2024</h1>
		<div class="logo">
			<img
				alt="gbwhatsapp logo"
				src="@/assets/logo.webp"
				title="Guía de uso de la versión mejorada anti-baneo de GB WhatsApp 2024 1" />
		</div>
		<table class="app-info">
			<tr>
				<td>App</td>
				<td>GBWhatsApp</td>
			</tr>
			<tr>
				<td>Tamaño</td>
				<td>77M</td>
			</tr>
			<tr>
				<td>Versión</td>
				<td>V18.10</td>
			</tr>
			<tr>
				<td>Desarrollador</td>
				<td>AlexMODs</td>
			</tr>
			<tr>
				<td>Sistemas</td>
				<td>Android 5+</td>
			</tr>
			<tr>
				<td>Fuente</td>
				<td>
					<a href="/">{{ host }}</a>
				</td>
			</tr>
			<tr>
				<td>Frecuencia de actualización</td>
				<td>Irregular</td>
			</tr>
			<tr>
				<td>Registro de cambios</td>
				<td>Protección anti-baneo mejorada</td>
			</tr>
		</table>

		<div
			exp-data="show_gbhome_download"
			class="big-download-btn top-download fix has-exp"
			data-exposure="1"
			@click="download(apk)">
			<img
				:src="downloadicon"
				alt="descargar"
				class="download-img" />
			<div class="download-name">Descargar GBWhatsApp</div>
		</div>

		<!-- Tabla de contenido -->
		<div
			id="toc_container"
			class="no_bullets">
			<section
				class="table-content"
				v-show="!tableHide">
				<div
					class="table-content-title"
					@click="tableHide = !tableHide">
					Tabla de contenido
					<img
						src="@/assets/minus.svg"
						alt="menos" />
				</div>

				<ul class="toc_list">
					<li>
						<a href="#tbc_1">
							<span class="toc_number toc_depth_1">1.</span>
							Descargar e instalar el nuevo GBWhatsApp actualizado
						</a>
					</li>
					<li>
						<a href="#tbc_2">
							<span class="toc_number toc_depth_1">2.</span>
							Características de GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_3">
							<span class="toc_number toc_depth_1">3.</span>
							La diferencia entre GBWhatsApp y WhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_4">
							<span class="toc_number toc_depth_1">4.</span>
							Requisitos para instalar GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_5">
							<span class="toc_number toc_depth_1">5.</span>
							Ventajas de usar GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_6">
							<span class="toc_number toc_depth_1">6.</span>
							¿Es seguro usar GBWhatsApp?
						</a>
					</li>
					<li>
						<a href="#tbc_7">
							<span class="toc_number toc_depth_1">7.</span>
							¿Cómo hacer una copia de seguridad de GB WhatsApp?
						</a>
					</li>
					<li>
						<a href="#tbc_8">
							<span class="toc_number toc_depth_1">8.</span>
							¿Cómo actualizar la versión caducada de GBWhatsApp?
						</a>
					</li>
					<li>
						<a href="#tbc_9">
							<span class="toc_number toc_depth_1">9.</span>
							Preguntas frecuentes sobre GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_10">
							<span class="toc_number toc_depth_1">10.</span>
							Reseñas de usuarios de GBWhatsApp
						</a>
					</li>
					<li>
						<a href="#tbc_11">
							<span class="toc_number toc_depth_1">11.</span>
							Conclusión
						</a>
					</li>
				</ul>
			</section>
			<section
				class="table-content hide"
				v-show="tableHide">
				<div
					class="table-content-title"
					@click="tableHide = !tableHide">
					Tabla de contenido
					<img
						src="@/assets/plus.svg"
						alt="más" />
				</div>
			</section>
		</div>

		<h2 id="tbc_1"><strong>Descargar e instalar el nuevo GBWhatsApp actualizado</strong></h2>
		<p>En este sitio web, hemos cargado el archivo APK original de GBWhatsApp modificado por AlexMODs. GB WhatsApp V18.10 ofrece nuevas y emocionantes funciones como estilo de estado de texto, rechazo de llamadas (en línea, fuera de línea, durante la llamada) y administración de grupos mejorada para administradores. Descárguelo hoy para disfrutar de estas funciones.</p>

		<p class="pic-fit">
			<img
				alt="descargar gbwhatsapp apk"
				src="@/assets/gb-1.webp"
				title="Guía de uso de la versión mejorada anti-baneo de GB WhatsApp 2024 2" />
		</p>

		<p>
			<strong>Paso 1:</strong>
			Habilite la función
			<strong>Orígenes desconocidos</strong>
			en su teléfono.
		</p>

		<p>
			<strong>Paso 2:</strong>
			Haga clic en el botón Descargar a continuación:
		</p>

		<div
			exp-data="show_gbhome_download"
			class="big-download-btn top-download fix has-exp"
			data-exposure="1"
			@click="download(apk)">
			<img
				:src="downloadicon"
				alt="descargar"
				class="download-img" />
			<div class="download-name">Descargar GBWhatsApp</div>
		</div>

		<p>
			<strong>Paso 3:</strong>
			Confíe en el archivo apk de GBWhatsApp y haga clic en
			<strong>Aceptar</strong>
			para instalarlo.
		</p>

		<p class="pic-fit landscape">
			<img
				alt="habilitar opción"
				title="Guía de uso de la versión mejorada anti-baneo de GB WhatsApp 2024 3"
				src="@/assets/NTI5ZDA5NWQ4MjQ2MWVlOTY1MmM4OWJkZmQ4MWIxMGFfVTQwaVo2NWM4N3NoNmd6T3F6REhzVzhWZmFZajh0c1ZfVG9rZW46SkZteWI2RXBpb3BuWTd4UERaT2NnV2dEbmdmXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<p>
			<strong>Paso 4:</strong>
			Inicie la aplicación y verifique su número de teléfono.
		</p>

		<p class="pic-fit landscape">
			<img
				alt="instalar gbwhatsapp apk"
				title="Guía de uso de la versión mejorada anti-baneo de GB WhatsApp 2024 4"
				src="@/assets/YjhlZjc4NGUwNDY0MWQ0ZTA3Mjg4OTc2Yzk3ZGE5NjFfaGVHalliYTVscUNBODIyZ0RkOGxjekJrTDJGZDJiTWtfVG9rZW46Q2VhWWJ4eGxKb0diakd4RGQwd2NkenV3bkJiXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>

		<p>La interfaz de GBWhatsApp no es muy diferente de la de WhatsApp oficial. Si alguna vez has utilizado WhatsApp, sabrás cómo utilizar GBWhatsApp.</p>
		<p>En comparación con WhatsApp oficial, GBWhatsApp ofrece más funciones. Y también puedes ir a la configuración para experimentar aún más. Hemos enumerado las diferentes características de GBWhatsApp que puedes utilizar.</p>
		<p class="feature-title"><strong>Respuesta automática</strong></p>
		<p>En primer lugar, puedes utilizar esta función de respuesta automática cuando quieras responder a cualquiera de tus amigos en cualquier momento. Puedes utilizarla para promocionar tu negocio enviando mensajes masivos ilimitados a cualquier chat o grupo.</p>
		<p class="feature-title"><strong>Temas</strong></p>
		<p>Los temas se han incluido en GBWA y ahora es una de las mejores características del mod. Te permiten cambiar el diseño de tu aplicación tantas veces como quieras y cuando quieras. Hay muchos temas disponibles, y el desarrollador está añadiendo más continuamente.</p>
		<p class="feature-title"><strong>Mensajes anti-borrado</strong></p>
		<p>Viene con una función de mensajes anti-revocación, los mensajes eliminados por alguien que no seas tú, todavía puedes ver esos mensajes eliminados por defecto. No respondas a los mensajes eliminados si no es necesario.</p>
		<p class="feature-title"><strong>Enviador de mensajes masivos</strong></p>
		<p>Esta herramienta te permite enviar mensajes masivos, lo que significa que puedes enviar mensajes ilimitados a tus contactos y divertirte con tus amigos con esta increíble herramienta.</p>
		<p class="feature-title"><strong>Enviar imágenes máximas</strong></p>
		<p>Además, en comparación con WhatsApp oficial, puedes enviar más de 90 imágenes a la vez. Además, puedes enviar un videoclip de 50 MB y un clip de audio de 100 MB a tus contactos.</p>
		<p class="feature-title"><strong>Ocultar estado visto</strong></p>
		<p>Siempre puedes ver y descargar el estado de tu amigo, pero si no quieres que sepan que has visto su estado, puedes hacerlo con un solo clic.</p>
		<p class="feature-title"><strong>Enviar imágenes de alta calidad</strong></p>
		<p>A veces, necesitamos enviar imágenes con la calidad original, y para ello, utilizamos la opción de documentos. Ahora, GBWhatsApp te permite enviar imágenes con la más alta calidad.</p>
		<p class="feature-title"><strong>Modo DND</strong></p>
		<p>A muchos usuarios les gusta disfrutar de un trabajo tranquilo. Ve a activar el modo DND cuando estés utilizando otra aplicación en tu teléfono Android. Te permite dejar de recibir mensajes hasta que se desactive.</p>
		<p class="pic-fit landscape">
			<img
				alt="Modo DND"
				title="Guía para usar la versión mejorada anti-baneo de GB WhatsApp 2024 5"
				src="@/assets/ODJjYTk4OTBkMjljYjhjMzE0YzFkYTI1NTkyNjhmZmJfN3pUdFMya1pMclppR1dJcXdQTjhpaUQ3VDd4N0Jua2dfVG9rZW46U2h1d2JHdHE4b3Q5bXp4QWhiTGNxbGJybkJoXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>
		<p class="feature-title"><strong>Descargar estado</strong></p>
		<p>Otra gran característica de esta aplicación es que puedes descargar las fotos y vídeos de estado enviados por otros contactos. Ya no necesitas utilizar ninguna aplicación de terceros, guarda cualquier tipo de estado de tu lista de contactos.</p>
		<p class="feature-title"><strong>Bloqueo de aplicaciones</strong></p>
		<p>El bloqueo de aplicaciones te permite requerir un patrón, PIN, contraseña o huella dactilar para acceder a la aplicación y a tus mensajes. Esto añade una capa de seguridad y mantiene tus mensajes ocultos incluso a las personas que tengan acceso a tu teléfono. El PIN y la contraseña pueden mostrarse mientras se escriben, si así lo deseas.</p>
		<p class="feature-title"><strong>Traducción adicional en el chat</strong></p>
		<p>GBWhatsApp ahora admite bengalí, tamil, gujarati, punjabi, urdu y vietnamita. Traduce instantáneamente cualquier mensaje de chat a más de 50 idiomas.</p>
		<p class="feature-title"><strong>Emojis</strong></p>
		<p>En lugar de responder a los mensajes con emojis, ahora puedes reaccionar a cualquier mensaje. La colección estándar de WhatsApp incluye cinco emojis. Sin embargo, con la aplicación GB WhatsApp, puedes personalizar tu propio conjunto de emojis para reaccionar.</p>
		<p class="feature-title"><strong>Nuevo estilo y botón para el widget de WhatsApp (añadido recientemente)</strong></p>
		<p>GB WhatsApp ha creado un nuevo estilo de widget de WhatsApp para que los usuarios elijan. Esta es una característica añadida recientemente y atrae a muchos usuarios.</p>
		<p class="pic-fit landscape">
			<img
				alt="Añadir widget"
				title="Guía para usar la versión mejorada anti-baneo de GB WhatsApp 2024 6"
				src="@/assets/MDVhZDM5ZjMxNjZkMDY1ODJiMzA3NDI3ZGY2NWNjNzhfRDZ5ZUVXNWVRdkM0cjhydmxheHV2VVlndGZma1BEYWhfVG9rZW46VExldmJKNHlTb2RCekl4Uzc3UWM3TVkzbmNsXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>
		<p class="feature-title"><strong>Ir al primer mensaje</strong></p>
		<p>La nueva versión actualizada de GB WhatsApp permite a los usuarios ir al primer mensaje, simplemente haciendo clic en la opción. Esto ahorra tiempo en el desplazamiento hacia arriba y hacia abajo para encontrar el primer mensaje en un chat.</p>
		<p>
			<strong>Nota</strong>
			: Una cosa que debes tener en cuenta es que GBWhatsApp no está disponible para el sistema iOS. Por lo tanto, los usuarios de iPhone deben utilizar el oficial o buscar otras versiones modificadas.
		</p>
		<h2 id="tbc_3"><strong>La diferencia entre GBWhatsApp y WhatsApp</strong></h2>
		<table class="d-table">
			<tr>
				<th>Función</th>
				<th>GB WhatsApp</th>
				<th>WhatsApp</th>
			</tr>
			<tr>
				<td>Modo Fantasma</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Alta Calidad de Imágenes</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Desactivar Llamadas</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Mensajes Masivos</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Mensajes no Leídos</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Ocultar Estado en Línea</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Respuesta Automática</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Anti-Eliminar</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Guardar Estado</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>4000+ Temas</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Cambiar Ticks Azules</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Ver Mensaje Editado</td>
				<td>✔️</td>
				<td>❌</td>
			</tr>
			<tr>
				<td>Chat con IA</td>
				<td>❌</td>
				<td>✔️</td>
			</tr>
			<tr>
				<td>Mensaje Automático</td>
				<td>✔️</td>
				<td>✔️</td>
			</tr>
			<tr>
				<td>Duración del Estado</td>
				<td>7 minutos</td>
				<td>30 segundos</td>
			</tr>
			<tr>
				<td>Límite de Mensaje de Voz</td>
				<td>100MB</td>
				<td>16MB</td>
			</tr>
			<tr>
				<td>Soporte</td>
				<td>Soporte oficial disponible</td>
				<td>
					Foros como
					<a
						href="https://www.reddit.com/r/GBWhatsapp/"
						rel="nofollow"
						target="_blank">
						Reddit
					</a>
				</td>
			</tr>
		</table>
		<h2 id="tbc_4"><strong>Requisitos para instalar GBWhatsApp</strong></h2>
		<table>
			<tr>
				<th>Elementos</th>
				<th>Requisitos</th>
			</tr>
			<tr>
				<td>Acceso a Internet</td>
				<td>Conexión WiFi</td>
			</tr>
			<tr>
				<td>Sistema del Teléfono</td>
				<td>Solo Android</td>
			</tr>
			<tr>
				<td>Root</td>
				<td>No necesario</td>
			</tr>
			<tr>
				<td>Almacenamiento</td>
				<td>Suficiente espacio de almacenamiento interno</td>
			</tr>
		</table>

		<h2 id="tbc_5"><strong>Los beneficios de usar GB WhatsApp</strong></h2>
		<p>
			<strong>Opciones de personalización:</strong>
			GB WhatsApp te permite personalizar tu experiencia de chat con temas, fuentes y fondos personalizados. Para aprovechar al máximo esta función, explora la biblioteca de temas de la aplicación y prueba diferentes combinaciones que se ajusten a tu estilo.
		</p>
		<p>
			<strong>Mejoras en la privacidad:</strong>
			Con funciones como ocultar tu estado en línea, los ticks dobles y los indicadores de escritura, GB WhatsApp ofrece un mayor control sobre tu privacidad. Utiliza estas configuraciones sabiamente para mantener la discreción en tus chats sin aparecer desconectado para tus contactos cuando sea necesario.
		</p>
		<h2 id="tbc_6"><strong>¿Es seguro usar GB WhatsApp en lugar del WhatsApp oficial?</strong></h2>
		<p>GB WhatsApp APK es una versión modificada basada en WhatsApp sin operaciones riesgosas. La preocupación principal al usar GB WhatsApp es el riesgo de que tu cuenta sea bloqueada por WhatsApp. No hay evidencia concreta que sugiera que la aplicación en sí sea insegura. Los usuarios deben estar al tanto de los riesgos potenciales de bloqueo, pero pueden estar tranquilos sabiendo que, hasta ahora, no se han demostrado vulnerabilidades de seguridad significativas en relación con la aplicación GB WhatsApp.</p>
		<p>Registrar una nueva cuenta o usar siempre la versión más reciente de GB WhatsApp puede ayudar a mejorar la seguridad de tu cuenta. Manteniendo la aplicación actualizada, reduces las posibilidades de encontrar problemas, ya que las versiones más recientes a menudo abordan vulnerabilidades potenciales e incluyen mejoras diseñadas para proteger las cuentas de los usuarios.</p>
		<h2 id="tbc_7"><strong>¿Cómo hacer una copia de seguridad de los datos de GB WhatsApp?</strong></h2>
		<p>
			GB WhatsApp no admite copias de seguridad directas del historial de chats o archivos multimedia en cuentas de Google; en su lugar, todos los mensajes se almacenan localmente en la memoria de tu teléfono. Para transferir o hacer una copia de seguridad de tus chats, deberás acceder manualmente a los archivos a través del
			<strong>Administrador de archivos</strong>
			de tu teléfono. Encuentra la carpeta de GB WhatsApp y copia todos los archivos a tu computadora.
		</p>
		<p>Para más detalles, puedes leer nuestros blogs.</p>
		<h2 id="tbc_8"><strong>¿Cómo actualizar la versión caducada de GB WhatsApp?</strong></h2>
		<p>GB WhatsApp se actualiza de manera irregular. Si GB WhatsApp caduca, los usuarios deben descargar la nueva versión actualizada para proteger su cuenta. Una vez que tu GB WhatsApp caduque, puedes ir a nuestro sitio para descargar el APK más reciente para Android. O actualiza la aplicación directamente siguiendo estos pasos:</p>
		<ul>
			<li>Inicia GB WhatsApp y ve a Configuración de GB.</li>
			<li>
				Busca la opción
				<strong>Actualizaciones</strong>
				y verifica si hay puntos rojos que indiquen una nueva versión.
			</li>
			<li>La aplicación GB WhatsApp puede pedirte que actualices a una nueva versión si se ha lanzado.</li>
		</ul>
		<div class="faq">
			<h2 id="tbc_9"><strong>Preguntas frecuentes sobre GB WhatsApp</strong></h2>
			<div
				v-for="(item, index) in faqs"
				:key="index"
				class="dropdown-faq">
				<h4 @click="toggleFaq(index)">{{ item.question }}</h4>
				<div
					v-if="activeIndex === index"
					class="content">
					<p>{{ item.answer }}</p>
				</div>
			</div>
		</div>
		<!-- tbc_10 -->
		<h2 id="tbc_10">Opiniones de usuarios sobre GBWhatsApp</h2>
		<p class="text-center">¿Dónde podemos conocer las ventajas de GBWhatsApp? Siempre en las opiniones de los usuarios.</p>
		<p class="text-center">⭐⭐⭐⭐⭐</p>
		<p class="pic-fit landscape">
			<img
				alt="revisión de gbwhatsapp"
				src="@/assets/N2YxMTBkNzk2ODI4YTc3N2EwYWQxMTA1Y2E3YWE1MDhfS0JQazB6RnNRb2ZlRUdwa0tQMG1ESnN5RzNxQzVzeGFfVG9rZW46WG5TQWJ6cXpab1RCNFZ4YnA4amNhQ3ZIbnRkXzE3MTg5NTYyMzE6MTcxODk1OTgzMV9WNA.webp" />
		</p>
		<h2 id="tbc_11">Conclusión</h2>
		<p>Es fácil ver que los usuarios de GBWhatsApp prefieren usarlo porque desbloquea un mundo de personalización y funciones de privacidad. Únete al grupo de usuarios de GBWhatsApp y disfruta de una experiencia de mensajería ilimitada. No olvides añadir nuestra página a tus favoritos.</p>
	</section>
</template>

<script>
import '@/css/default.scss'
import { mapGetters } from 'vuex'

export default {
	...{
		metaInfo: {
			title: 'Sitio Oficial: Descarga la Versión Anti-Ban de GB WhatsApp 2024 (¡Nueva Actualización!)',
			meta: [
				{ name: 'description', content: '¿Cómo usar GBWhatsApp? Puedes descargar la última versión de GBWhatsApp con protección anti-ban primero y leer la guía paso a paso.' },
				{ name: 'title', content: 'Descarga la Versión Anti-Ban de GBWhatsApp y Consulta la Guía del Usuario' },
				
				{ property: 'og:title', content: 'Descarga la Versión Anti-Ban de GBWhatsApp y Consulta la Guía del Usuario' },
				{ property: 'og:description', content: '¿Cómo usar GBWhatsApp? Puedes descargar la última versión de GBWhatsApp con protección anti-ban primero y leer la guía paso a paso.' },
			],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.net/es/"
            }]
		},
	},
	data() {
		return {
			from: null,
			filename: null,
			partners: null,
			updateTime: null,
			clicks: null,
			pageIntSuccess: null,
			apk: null,
			tableHide: true,
			activeIndex: null,
			faqs: [
				{
					question: '¿Está disponible para iPhone?',
					answer: 'No, GB WhatsApp no está disponible para el sistema iOS.',
				},
				{
					question: '¿GBWhatsApp tiene una versión web o de escritorio?',
					answer: 'No. Pero puedes usar una aplicación de terceros para reflejar la pantalla de tu teléfono en tu escritorio.',
				},
				{
					question: '¿Qué es GB WhatsApp?',
					answer: 'Es una versión modificada de WhatsApp que ofrece más funciones que el WhatsApp original. Atrae a millones de usuarios para descargarlo.',
				},
			],
		}
	},
	computed: {
		...mapGetters(['showDebug', 'host', 'downloadicon']),
	},
	created() {
		this.initParams()
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		toggleFaq(index) {
			this.activeIndex = this.activeIndex === index ? null : index
		},
		download(apk) {
			this.$global.download(apk)
		},
		initParams() {
			this.from = this.$global.GetQueryString('from')
			this.filename = this.$global.GetQueryString('filename')
		},
		fetchData() {
			if (this.from) {
				this.getAPK(true, this.from, this.filename)
			} else {
				this.getAPK()
				this.getOfficialApk()
			}
		},
		updateAPK() {
			this.$emit('update-apk', this.apk)
		},
		getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
			this.$server
				.getAPPInfo({
					promotionChannel: from,
					promotionName: filename,
				})
				.then((res) => {
					if (res.code === 200) {
						this.partners = res.data.partners
						this.updateTime = res.data.updateTime
						this.clicks = res.data.clicks
						this.pageIntSuccess = true
						if (isFromQuery) {
							this.apk = res.data.apk
							this.updateAPK()
						}
					}
				})
				.catch((err) => {
					console.error('Error fetching APK:', err)
				})
		},
		getOfficialApk() {
			this.$server
				.getOfficialApk({
					domain: 'gbpro.download',
					appName: 'GB_gbpro.download',
				})
				.then((res) => {
					if (res.code === 200) {
						this.apk = res.data.apk
						this.updateAPK()
						console.log('APK fetched successfully')
					}
				})
				.catch((err) => {
					console.error('Error fetching official APK:', err)
				})
		},
	},
}
</script>
