<template>
<!-- Features -->
<div id="feature-group">
    <div v-for="(item, index) in features" :key="index" class="feature-item">
        <!-- <div class="feature-img"> -->
            <img :src="item.img" :alt="item.alt"/>
        <!-- </div> -->
        <h6>{{item.title}}</h6>
        <p>{{item.text}}</p>
    </div>
</div>
</template>

<script>
export default {
    props: {
        features: {
            type: Array,
            required: true
        }
    }
}
</script>